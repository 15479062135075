<template>
  <div class="container mx-auto p-4 bg-gray-100 rounded-md shadow-lg">
    <div class="p-4 rounded-md bg-gray-100">
      <h2 class="mb-1 font-extrabold text-xl sm:text-2xl text-green-700">Great News!</h2>
      <p class="mb-2 text-sm md:text-base text-gray-800">
  We'd like to make a cash offer on your property at <strong>{{ addressData.street ? addressData.street + ', ' : '' }}{{ addressData.postcode ? addressData.postcode + ', ' : '' }}{{ addressData.country ? addressData.country : '' }}</strong>.
</p>
<p class="mb-4 font-extrabold text-sm md:text-base text-gray-800">
Please enter your details below:</p>

<form id="request-cash-offer" @submit.prevent="submitForm">
<div class="mb-4">
  <input
  class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
    id="firstName"
    type="text"
    v-model="firstName"
    placeholder="First Name"
    @blur="validateFirstName"
  />
  <p class="text-red-500 text-xs italic" v-if="firstNameError">{{ firstNameError }}</p>
</div>

<div class="mb-4">
  <input
  class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
    id="lastName"
    type="text"
    v-model="lastName"
    placeholder="Last Name"
    @blur="validateLastName"
  />
  <p class="text-red-500 text-xs italic" v-if="lastNameError">{{ lastNameError }}</p>
</div>

<div class="mb-4">
  <input
  class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
    id="email"
    type="text"
    v-model="email"
    placeholder="Email"
    @blur="validateEmail"
  />
  <p class="text-red-500 text-xs italic" v-if="emailError">{{ emailError }}</p>
</div>

<div class="mb-4">
  <input
  class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
    id="phone"
    type="text"
    v-model="phone"
    placeholder="Contact Number"
    @blur="validatePhone"
  />
  <p class="block text-xs font-medium text-gray-500 small-device-text">We need a valid number to make an offer</p>
  <p class="text-red-500 text-xs italic" v-if="phoneError">{{ phoneError }}</p>
</div>
      <div class="mb-4">
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="form-checkbox text-green-500"
            v-model="privacyPolicy"
          />
          <span class="ml-2 text-xs">I agree to the <a href="/privacy-policy" class="text-blue-500" target="_blank">Privacy Policy</a>.</span>
        </label>
        <p class="text-red-500 text-xs italic" v-if="privacyPolicyError">{{ privacyPolicyError }}</p>
      </div>

      <div class="mb-4">
  <label class="inline-flex items-center">
    <input
      type="checkbox"
      class="form-checkbox text-green-500"
      v-model="underMarketValue"
    />
    <span class="ml-2 text-xs">I understand the offer I receive will be below market value.</span>
  </label>
  <p class="text-red-500 text-xs italic" v-if="underMarketValueError">{{ underMarketValueError }}</p>
</div>


      <div class="mb-4">
  <div class="flex items-center justify-between">
    <button
      class="border-b-4 border-green-800 mt-0 text-lg block w-full items-center px-8 py-3 bg-green-600 text-white rounded-2xl font-bold hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      type="submit">
      Request Your Cash Offer→
    </button>
  </div>
  <p class="mt-4 text-xs text-gray-500">
    By selecting 'Request Your Cash Offer→', you consent to being contacted by us via phone, SMS, email, and WhatsApp to discuss your property offer.
  </p>
</div>

      </form>
      <div class="mt-2 flex justify-center">
        <p class="flex items-center text-gray-500">
          <i class="mr-1 text-sm items-center fas fa-shield-alt text-green-600"></i> Safe, secure & confidential
        </p>
      </div>
    </div>

    <div v-if="shouldShowPrices">
    <!-- Sold House Prices Section -->
  <div class="mt-8 w-full p-4 bg-white rounded-md shadow-lg">
    <h3 class="mb-4 text-lg font-semibold">Sold House Prices for Last 3 Years</h3>
    <p class="text-gray-600 py-2 px-4 text-sm sm:text-base">This selection showcases properties last sold within the last 3 years in {{ addressData.postcode ? addressData.postcode + ', ' : '' }} which may bear resemblance to your property.</p>


    
    <!-- Loading Spinner Placeholder -->
    <div v-if="propertyDataLoading" class="flex justify-center items-center">
      <span class="loader animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></span>
    </div>
    
    <!-- Property Prices Data Display -->
    <div v-else>
      
        <!-- Card Layout for Small and Medium Screens -->
        <div v-if="sortedPropertyPrices.length > 0" class="block md:hidden">
        <div v-for="(item, index) in sortedPropertyPrices" :key="index" class="mb-4 p-4 bg-gray-50 rounded-lg shadow">
          <div><strong>Date:</strong> {{ formatDate(item.date) }}</div>
          <div><strong>Address:</strong> {{ item.address }}</div>
          <div><strong>Type:</strong> {{ item.type ? toTitleCase(item.type) : 'Unknown' }}</div>
          <!--<div><strong>Beds:</strong> {{ item.bedrooms !== null ? item.bedrooms : 'N/A' }}</div>-->
          <div><strong>Price:</strong> £{{ item.price.toLocaleString() }}</div>
          <!--<div><div><strong>Current Value:</strong> {{ item.currentValue }}</div>  -->

        
        </div>
      </div>

      <!-- Desktop View: Table Layout -->
      <table v-if="sortedPropertyPrices.length > 0" class="min-w-full divide-y divide-gray-200 hidden md:table">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date Sold
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Address
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Type
            </th>
          <!--  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Beds
            </th> -->
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Sold Price
            </th>
          <!---  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Estimated Value
            </th> -->
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(item, index) in sortedPropertyPrices" :key="index">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ formatDate(item.date) }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ item.address }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ item.type ? toTitleCase(item.type) : 'Unknown' }}
            </td>
            <!---<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ item.bedrooms !== null ? item.bedrooms : 'N/A' }}
            </td>-->
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              £{{ item.price.toLocaleString() }}
            </td>
            <!---<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ item.currentValue.toLocaleString() }}
            </td>-->
          </tr>
        </tbody>
      </table>

      <div v-else class="py-4 text-center text-red-500">
        No property price data available.
      </div>
    </div>
    <p class="text-sm text-gray-600 py-2 px-4">This data is sourced from the UK Land Registry and may not be fully up-to-date. Please note that details provided here may not reflect the most current information.</p>

  </div>

  <!-- 
<div class="mt-8 w-full p-4 bg-white rounded-lg shadow">
    <h3 class="mb-4 text-lg font-semibold">On Market Property</h3>
    <p class="text-gray-600 py-2 px-4 text-sm sm:text-base">This selection showcases properties currently available on the market within {{ this.addressData.localityTownCounty }} which may bear resemblance to your property. Explore these options to make informed comparisons.</p>



  <div v-if="propertiesForSaleLoading" class="flex justify-center items-center">
    <span class="loader animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></span>
  </div>
  

  <div v-else-if="propertiesForSale.length > 0">
    <div class="block md:hidden">
      <div v-for="property in propertiesForSale.filter(prop => !prop.premListing)" :key="property.id" class="mb-4 p-4 bg-gray-100 rounded-lg shadow">
        <div><strong>Date Added:</strong> {{ formatDate(property.dateAdded) }}</div>
        <div><strong>Address:</strong> {{ property.address }} </div>
        <div><strong>Beds:</strong> {{ property.bedrooms }}</div>
        <div><strong>Type:</strong> {{ property.propertySubType }}</div>
        <div><strong>Price:</strong> {{ property.price }}</div>
      </div>
    </div>

    <table class="min-w-full divide-y divide-gray-200 hidden md:table">
      <thead class="bg-gray-50">
        <tr>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Date Added
          </th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Address
          </th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Beds
          </th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Type
          </th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Price
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="property in propertiesForSale.filter(prop => !prop.premListing)" :key="property.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ formatDate(property.dateAdded) }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ property.address }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ property.bedrooms }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ property.propertySubType }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ property.price }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
   <div v-else class="py-4 text-center text-red-500">
    <p>No properties for sale found.</p>
  </div>
</div>-->
</div>


  </div>
</template>
         
<script>
import axios from "axios";

const dataSoapApiKey = "3c6673da8ed24dadaffd5f7663631f5ed731304b6d24412a9c80384090af46c7"; 


export default {
  props: {
  addressData: {
    type: Object,
    default: () => ({}),
  },
  gclid: {
      type: String,
      default: '',
  },
  quizData: {
    type: Object,
    default: () => ({}),
  },
  utmCampaign: {
    type: String,
    default: '',
  },
  utmSource: {
    type: String,
    default: '',
  },
  utmMedium: {
    type: String,
    default: '',
  },
  utmKeyword: {
    type: String,
    default: '',
  },
  utmMatch: {
    type: String,
    default: '',
  },
  utmDevice: {
    type: String,
    default: '',
  },
},



  name: "ContactForm",
  data() {
    return {
      propertiesString: '', // Declare propertiesString here
      highEndValue: 0, // Stores calculated high end value
      lowEndValue: 0, // Stores calculated low end value
      propertyDetails: null, // This will store the fetched property details
      propertyDataLoading: false,
      propertyPrices: null,
      propertyDataError: false,
      propertiesForSale: [],
      matchedPropertyValue: null,
      propertiesForSaleLoading: false,
      propertiesForSaleError: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
      messageError: "",
      privacyPolicy: false,
      privacyPolicyError: "",
      isSubmitting: false,
      submitSuccess: false,
      submitError: false,
      identifier: '', // Initial value, could be an empty string or null depending on your logic
      quizDataMapping: {
      apiParam1: "on_market",
      apiParam2: "prop_type",
      apiParam3: "beds",
      apiParam4: "reasons_for_sale",
      apiParam5: "speed_of_sale",
      apiParam6: "property_condition",
      phoneHlrStatus: null,  // This will store the active status
      underMarketValue: false,
      underMarketValueError: "",
      country: "", // New property for the country
      addressData: { postcode: '' }, // Ensure this is updated based on your application logic
      propertyPrices: null, 
      //numberOfBedrooms: '',


    },
    localUtmCampaign: this.utmCampaign,
    localUtmSource: this.utmSource,
    localUtmMedium: this.utmMedium,
    localUtmKeyword: this.utmKeyword,
    localUtmDevice: this.utmDevice,
    localUtmMatch: this.utmMatch,
    localAdId: this.adId,
    localAdSetId: this.adSetId,
    localGclid: this.gclid,
    };
  },

  async mounted() {
  if (this.addressData && this.addressData.postcode) {
   // await this.fetchPropertyPrices(); 
    await this.fetchIdentifier(); // Make sure identifier is fetched before proceeding
    this.fetchPropertyDetails();
    // If fetchPropertiesForSale needs to be called here, ensure fetchIdentifier updates this.identifier correctly
   //this.fetchPropertiesForSale(); // This assumes fetchIdentifier successfully updates the identifier
  }
},


  watch: {
    'quizData.apiParam2': function(newVal) {
    // This watcher will trigger every time the number of bedrooms changes.
    if (newVal && this.identifier) {
      // Ensure the number of bedrooms is fetched from quizData correctly
     this.fetchPropertiesForSale();
     this.fetchPropertyDetails();
      
    }
  },
    
    'quizData.apiParam3': function(newVal) {
    // This watcher will trigger every time the number of bedrooms changes.
    if (newVal && this.identifier) {
      // Ensure the number of bedrooms is fetched from quizData correctly
     //this.fetchPropertiesForSale();
    }
  },
  'identifier': function(newVal) {
    // This watcher will trigger every time the identifier changes.
    if (newVal && this.quizData.apiParam3) {
      // Ensure fetchPropertiesForSale is called only when both values are set
      this.fetchPropertiesForSale();
    }
  },
  'addressData.postcode': {
    handler(newVal) {
      if (newVal) {
        this.fetchPropertyPrices();
        this.fetchIdentifier();
      }
    },
    immediate: true, // This ensures the watcher is also triggered on initial load
  },
},

    created() {
    if (this.localUtmCampaign) {
      localStorage.setItem("utm_campaign", this.localUtmCampaign);
    } else {
      const storedUtmCampaign = localStorage.getItem("utm_campaign");
      if (storedUtmCampaign) {
        this.localUtmCampaign = storedUtmCampaign;
      }
    }

    if (this.localGclid) {
      localStorage.setItem("gclid", this.localGclid);
    } else {
      const storedGclid = localStorage.getItem("gclid");
      if (storedGclid) {
        this.localGclid = storedGclid;
      }
    }

    if (this.localUtmSource) {
      localStorage.setItem("utm_source", this.localUtmSource);
    } else {
      const storedUtmSource = localStorage.getItem("utm_source");
      if (storedUtmSource) {
        this.localUtmSource = storedUtmSource;
      }
    }

    if (this.localUtmMedium) {
      localStorage.setItem("utm_medium", this.localUtmMedium);
    } else {
      const storedUtmMedium = localStorage.getItem("utm_medium");
      if (storedUtmMedium) {
        this.localUtmMedium = storedUtmMedium;
      }
    }

    if (this.localUtmKeyword) {
      localStorage.setItem("utm_keyword", this.localUtmKeyword);
    } else {
      const storedUtmKeyword = localStorage.getItem("utm_keyword");
      if (storedUtmKeyword) {
        this.localUtmKeyword = storedUtmKeyword;
      }
    }

    if (this.localUtmMatch) {
      localStorage.setItem("utm_matchtype", this.localUtmMatch);
    } else {
      const storedUtmMatch = localStorage.getItem("utm_matchtype");
      if (storedUtmMatch) {
        this.localUtmMatch = storedUtmMatch;
      }
    }

    if (this.localUtmDevice) {
      localStorage.setItem("utm_device", this.localUtmDevice);
    } else {
      const storedUtmDevice = localStorage.getItem("utm_device");
      if (storedUtmDevice) {
        this.localUtmDevice = storedUtmDevice;
      }
    }

  },


  methods: {

    toTitleCase(str) {
    return str
      // First, replace underscores with spaces to handle snake_case
      .replace(/_/g, ' ')
      // Split the string into words
      .split(' ')
      // Transform each word to capitalize the first letter and make the rest lowercase
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      // Join the words back into a single string with spaces
      .join(' ');
  },

    formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  },

    // Add handleAddressSelected method
    handleAddressSelected(addressData) {
      // Handle address data emitted from AddressForm.vue
      console.log("Address data:", addressData);
    },

    // Add handleIdentifierFetched method
    handleIdentifierFetched(identifier) {
    // Handle identifier emitted from AddressForm.vue
    console.log("Identifier:", identifier);
    this.identifier = identifier;
    // Log the identifier here
    console.log("Identifier output:", this.identifier);
    this.fetchPropertiesForSale();
  },

  extractHouseNumber() {
  // Assuming the house number is at the start, possibly followed by a letter and potentially separated by spaces or other non-digit characters (e.g., "54B High Street")
  const houseNumberMatch = this.addressData.street.match(/^\d+\w?/);
  if (houseNumberMatch) {
    return houseNumberMatch[0].trim(); // Returns the matched house number, trimmed of any leading/trailing whitespace
  }
  return ''; // Return an empty string if no match is found
},

async calculateHighLowValues() {
    // Wait for propertyDetails to be fetched if it's not yet available
    if (!this.propertyDetails) {
      await this.fetchPropertyDetails(); // Ensure this method is async and fetches propertyDetails
    }

  if (this.propertyDetails && this.propertyDetails.property && this.propertyDetails.property.estimatedCurrentValue) {
    const estimatedValue = this.propertyDetails.property.estimatedCurrentValue.value;

    // Calculate HighEnd and LowEnd values by adding or subtracting £100,000
    const HighEnd = estimatedValue + 100000;
    const LowEnd = estimatedValue - 70000;

    // Calculate values with a 3% increase and approximate cash offer
    const IncreasedByThreePercent = estimatedValue * 1.03; // Increase by 3%
    let ApproxCashOffer = estimatedValue * 0.70; // Approximate cash offer, not exactly a decrease by 3%

    // Round down to the nearest hundred for the approximate cash offer
    ApproxCashOffer = Math.floor(ApproxCashOffer / 100) * 100;

    // Format the approximate cash offer as a string with currency symbol
    const formattedApproxCashOffer = `£${Intl.NumberFormat('en-GB').format(ApproxCashOffer)}`;

    // Assign calculated values to your component's data properties to use in the template
    this.highEndValue = HighEnd;
    this.lowEndValue = LowEnd;
    this.increasedByThreePercentValue = IncreasedByThreePercent;
    this.decreasedApproxCashOffer = formattedApproxCashOffer;

    //console.log(`HighEnd: £${HighEnd.toLocaleString()}`, `LowEnd: £${LowEnd.toLocaleString()}`);
    //console.log(`Increased by 3%: £${IncreasedByThreePercent.toLocaleString()}`, `Approx Cash Offer: ${formattedApproxCashOffer}`);
  } else {
    console.log("Property details are not available for calculation.");
  }
},

async fetchPropertyDetails() {
  const houseNumber = this.extractHouseNumber();
  const postcode = this.addressData.postcode;
  if (!houseNumber || !postcode) {
    console.error("House number or postcode is missing");
    return;
  }
},





async fetchPropertyPrices() {
  try {
    if (!this.addressData.postcode) {
      throw new Error('Postcode is required for property price data.');
    }
    
    this.propertyDataLoading = true;

    const options = {
      method: 'POST',
      url: 'https://uk-property-data.p.rapidapi.com/propertytools.api.v1.Public/ListProperties',
      headers: {
        'content-type': 'application/json',
        'X-RapidAPI-Key': 'c4aaf31669mshedbda602fd9855bp1ba0b7jsn460b0be722a6',
        'X-RapidAPI-Host': 'uk-property-data.p.rapidapi.com'
      },
      data: JSON.stringify({ postcode: this.addressData.postcode })
    };

    const response = await axios.request(options);

    if (response.data && response.data.property && Array.isArray(response.data.property)) {
      this.propertyPrices = response.data.property.map(item => ({
        date: item.mostRecentTransaction?.date?.iso,
        displayDate: item.mostRecentTransaction?.date?.full || 'Unknown date',
        address: `${item.address.paon || ''}, ${item.address.street || ''}, ${item.address.locality || ''}, ${item.address.town || ''}, ${item.address.postcode || ''}`,
        type: item.type || 'Unknown',
        price: item.mostRecentTransaction?.pricePaid?.value || 'Unknown price',
        currentValue: item.estimatedCurrentValue?.amount || 'Unknown price',
        floorArea: item.estimatedCurrentValue?.floorArea || 'N/A',
        description: item.address.description || 'No description available',
        paon: '2', // Hardcode the PAON value here
      })).filter(item => {
        const currentDate = new Date();
        const twoYearsAgo = new Date().setFullYear(currentDate.getFullYear() - 3);
        return new Date(item.date) >= twoYearsAgo;
      }).sort((a, b) => new Date(b.date) - new Date(a.date));

      // Construct propertiesString by including the date, address, and price from the first property
      if (this.propertyPrices.length > 0) {
          const firstProperty = this.propertyPrices[0];
          this.propertiesString = `${firstProperty.displayDate} - ${firstProperty.address} - ${firstProperty.price}\n`;
      }

      // Append propertiesString to formData or use it as needed
      console.log(this.propertiesString);
    } else {
      this.propertyDataError = true;
      //console.error("No property price data available for the given postcode.");
    }
  } catch (error) {
    //console.error('Error fetching property prices:', error);
    this.propertyDataError = true;
  } finally {
    this.propertyDataLoading = false;
  }
},



async fetchIdentifier() {
  
  // Check if the required addressData properties are available
  if (!this.addressData.localityTownCounty) {
    console.error('Address data is incomplete or not available.');
    return null;
  }

},


async fetchPropertiesForSale() {
  await this.calculateHighLowValues(); // Ensure highEndValue and lowEndValue are calculated

  // Check if lowEndValue and highEndValue are valid
  if (!this.lowEndValue || !this.highEndValue) {
    console.error('Invalid lowEndValue or highEndValue.');
    return;
  }
  
  // Rest of the method remains unchanged
  console.log("fetchPropertiesForSale called");
  // Ensure identifier is available
  if (!this.identifier) {
    console.log("Using identifier:", this.identifier);
    console.log("Identifier set:", this.identifier);
    console.error('Identifier is required to fetch properties for sale.');
    return;
  }

  // Inside fetchPropertiesForSale method in ContactForm component
  const numberOfBedrooms = this.quizData.apiParam3;
  //console.log("Number of Bedrooms from quizData:", numberOfBedrooms);

  const propertyType = this.quizData.apiParam2;
  console.log("Property Type:", propertyType);

  //const minPrice = Math.round(this.lowEndValue / 1000) * 1000;
  //const maxPrice = Math.round(this.highEndValue / 1000) * 1000;
  // Log minPrice and maxPrice to see their values
  //console.log("minPrice:", minPrice);
  //console.log("maxPrice:", maxPrice);

  if (!numberOfBedrooms) {
    console.log("Number of bedrooms is missing.");
    return;
  }

},

    validateFirstName() {
      if (!this.firstName) {
        this.firstNameError = "First name is required";
      } else {
        this.firstNameError = "";
      }
    },
    validateLastName() {
      if (!this.lastName) {
        this.lastNameError = "Last name is required";
      } else {
        this.lastNameError = "";
      }
    },

    validateEmail() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!this.email) {
        this.emailError = "Email is required";
      } else if (!emailPattern.test(this.email)) {
        this.emailError = "Invalid email address";
      } else {
        this.emailError = "";
      }
    },

    validatePrivacyPolicy() {
    if (!this.privacyPolicy) {
      this.privacyPolicyError = "You must agree to the Privacy Policy";
    } else {
      this.privacyPolicyError = "";
    }
  },

  validateUnderMarketValue() {
  if (!this.underMarketValue) {
    this.underMarketValueError = "You must acknowledge the offer will be under market value";
  } else {
    this.underMarketValueError = "";
  }
},


    async validatePhone() {
  const phonePattern = /^(\+?\d{1,4}[-\s]?)?(\d{1,4}[-\s]?){1,7}\d{1,4}$/;
  if (!this.phone) {
    this.phoneError = "Phone number is required";
  } else if (!phonePattern.test(this.phone)) {
    this.phoneError = "Invalid phone number";
  } else {
    try {
      const isValid = await this.validateMobileNumberWithDatasoap();
      if (isValid === false) {
        this.phoneError = "Invalid mobile number";
      } else {
        this.phoneError = ""; // <-- Set phoneError to an empty string
      }
    } catch (error) {
      console.error("Error validating mobile number with DataSoap:", error);
      // Fallback to original phone number validation
      this.phoneError = "";
    }
  }
},




async validateMobileNumberWithDatasoap() {
  try {
    const response = await axios.post(
      'https://api.datasoap.co.uk/v2',
      {
        lookup: this.phone,
        checks: ['hlr']
      },
      {
        headers: {
          'Content-Type': 'application/json-patch+json',
          'accept': 'text/plain',
          'Authorization': `Bearer ${dataSoapApiKey}`
        }
      }
    );
    console.log("DataSoap API response:", response.data);

    // Check if the phone number is syntactically valid
    if (response.data.valid === true) {
      // Check if the country is the UK using the alpha2Code field
      if (response.data.syntax.additional.country.alpha2Code === 'GB') {
        // Set the phoneHlrStatus based on the active status of the phone number
        this.phoneHlrStatus = response.data.hlr.on ? 'active' : 'non-active';

        // Clear any previous error messages as the number is valid and from the UK
        this.phoneError = "";

        // Return true if all conditions are satisfied
        return true;
      } else {
        // Set error message if the number is not from the UK
        this.phoneError = 'Only UK mobile numbers are allowed';
        return false;
      }
    } else {
      // Set error message if the phone number is not valid
      this.phoneError = 'Invalid mobile number';
      return false;
    }
  } catch (error) {
    console.error('Error validating mobile number with DataSoap:', error);

    // Fallback to original phone number validation using regex
    const phonePattern = /^(\+?\d{1,4}[-\s]?)?(\d{1,4}[-\s]?){1,7}\d{1,4}$/;
    if (!this.phone || !phonePattern.test(this.phone)) {
      this.phoneError = "Invalid phone number";
      return false;
    } else {
      // Clear the error if the fallback validation passes
      this.phoneError = "";
      return true;
    }
  }
},


    async submitForm() {
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      await this.validatePhone();
      this.validatePrivacyPolicy();
      this.validateUnderMarketValue(); // New validation

      if (this.firstNameError || this.lastNameError || this.emailError || (this.phoneError !== null && this.phoneError !== undefined && this.phoneError !== '') || this.privacyPolicyError || this.underMarketValueError) {
        return;
      }

      this.isSubmitting = true;
       // Generate propertiesString


      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      

      try {
        await this.fetchPropertyPrices();
        const formData = new FormData();
        formData.append("lp_offer_id", "7");
        formData.append("lp_campaign_id", "649d8e90affdf");
        formData.append("site_ref", "https://asaphousebuyers.co.uk");
        formData.append("first_name", this.firstName);
        formData.append("last_name", this.lastName);
        formData.append("email_address", this.email);
        formData.append("phone_home", this.phone);
        formData.append("accepted_terms", this.privacyPolicy ? "1" : "0");
        formData.append("sold_prices", this.propertiesString);
        if (this.addressData) {
        formData.append("address", this.addressData.street); // Update this line
        formData.append("postcode", this.addressData.postcode); // Update this line
        formData.append("country", this.addressData.country);
        formData.append("phone_hlr", this.phoneHlrStatus);  // Append the HLR status

       



         // Check if propertyDetails and its nested properties exist before attempting to append them
        if (this.propertyDetails && this.propertyDetails.property && this.propertyDetails.property.estimatedCurrentValue) {
          formData.append("estimated_value", this.propertyDetails.property.estimatedCurrentValue.amount);
          //formData.append("value", this.propertyDetails.property.estimatedCurrentValue.value.toString());
          //formData.append("confidence", this.propertyDetails.property.estimatedCurrentValue.confidence);
          //formData.append("floor_area", this.propertyDetails.property.estimatedCurrentValue.floorArea.toString());
          //formData.append("price_per_sqm", this.propertyDetails.property.estimatedCurrentValue.pricePerSqm.toString());
        }

          // Ensure you have the updated property details including EPC status
        if (this.propertyDetails && this.propertyDetails.epcStatus) {
          formData.append("epc_expiryDate", this.propertyDetails.epcStatus.expiryDate);
         // formData.append("epc_lodgementDate", this.propertyDetails.epcStatus.lodgementDate);
          //formData.append("epc_currentEnergyRating", this.propertyDetails.epcStatus.currentEnergyRating);
          //formData.append("epc_potentialEnergyRating", this.propertyDetails.epcStatus.potentialEnergyRating);
          formData.append("epc_statusMessage", this.propertyDetails.epcStatus.statusMessage);
          //formData.append("epc_expired", this.propertyDetails.epcStatus.expired.toString());
        }

        if (this.decreasedApproxCashOffer) {
          formData.append("approx_cash_offer", this.decreasedApproxCashOffer);
        }


         // Log FormData object or its entries
         console.log("FormData Object:", formData);
        }

          // Log FormData entries
        for (const pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

        // Append quizData fields to formData
        for (const key in this.quizData) {
          const apiParamName = this.quizDataMapping[key];
          if (apiParamName) {
            formData.append(apiParamName, this.quizData[key]);
          }
        }

        if (this.localUtmCampaign) {
        formData.append('utm_campaign', this.localUtmCampaign);
        console.log(`Appended utm_campaign to formData: ${this.localUtmCampaign}`);

       }


       if (this.localUtmSource) {
        formData.append('utm_source', this.localUtmSource);
        console.log(`Appended utm_source to formData: ${this.localUtmSource}`);
       }

       if (this.localUtmMedium) {
        formData.append('utm_medium', this.localUtmMedium);
        console.log(`Appended utm_medium to formData: ${this.localUtmMedium}`);
       }

       if (this.localUtmKeyword) {
        formData.append('utm_keyword', this.localUtmKeyword);
        console.log(`Appended utm_keyword to formData: ${this.localUtmKeyword}`);
       }

       if (this.localUtmDevice) {
        formData.append('utm_device', this.localUtmDevice);
        console.log(`Appended utm_device to formData: ${this.localUtmDevice}`);
       }


      if (this.localGclid) {
        formData.append('gclid', this.localGclid);
        console.log(`Appended gclid to formData: ${this.localGclid}`);
      }

       if (this.localUtmMatch) {
        formData.append('utm_matchtype', this.localUtmMatch);
        console.log(`Appended utm_matchtype to formData: ${this.localUtmMatch}`);
       }

       

        await axios.post("https://leadseeker.leadspediatrack.com/post.do", formData, config);

        this.submitSuccess = true;
        this.submitError = false;
      } catch (error) {
        this.submitSuccess = false;
        this.submitError = true;
        console.error("Error posting the form:", error);
      }

      this.isSubmitting = false;

      if (this.submitSuccess) {
        // At this point, we are sure that the lead score has been calculated,
        // and the form data has been successfully submitted.
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'leadSubmission',
          'lead_score': this.leadScore, // Make sure this is the correct and final value
          'email': this.email, // Make sure this.email contains the email value
          'gclid': this.gclid, // Make sure this.email contains the email value
        });

        // Redirect to the thank you page or display success message
        localStorage.setItem('userEmail', this.email);
        window.location.href = `/thank-you?email=${encodeURIComponent(this.email)}`;
    }
  },
},

computed: {
    sortedPropertyPrices() {
      // Ensure propertyPrices is an array
      if (Array.isArray(this.propertyPrices)) {
        // Create a new array from propertyPrices to avoid mutating the original array
        return [...this.propertyPrices]
          // Sort the new array by date in descending order (newest first)
          .sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      return []; // If propertyPrices is not an array, return an empty array
    },
    shouldShowPrices() {
      // Convert query parameter to a boolean value
      // Assuming you are using Vue Router
      return this.$route.query.showPrices !== 'false';
    },
  },
  isFormValid() {
    return (
      this.firstName &&
      this.lastName &&
      this.email &&
      this.phone &&
      this.privacyPolicy
      );
  },
}
</script>

<style>
@media (max-width: 640px) {
  .small-device-text {
    font-size: 0.525rem; /* Adjust the value to your preference */
  }
}
</style>
