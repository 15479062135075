<template>

  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-cash-request.jpg" alt="Sold">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Your Cash Offer Request - We're On It!</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">Thank you for sending us the details of your property for a free cash offer. We're thrilled to have the opportunity to work with you! Expect a friendly call from our team soon. We're here to offer a fair, no-obligation cash deal that you can comfortably evaluate at your pace.</p>
      

<p class="mt-6 max-w-3xl text-xl text-indigo-100">Got questions or thoughts about what's next? Don’t hesitate to jot them down. When we chat, we’ll make sure every query and concern you have is addressed. After all, we’re not just about making offers; we’re about making your property selling experience as smooth and enjoyable as possible.</p>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">If our offer hits the right spot for you, we’ll walk you through every step of the closing process. Think of us as your buddies in the real estate world – here to ensure everything goes without a hitch.</p>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">Ready for a refreshingly easy selling experience? If anything pops up in your mind or you just want to talk things through, feel free to reach out anytime. We’re always here and happy to help.</p>
<p class="mt-6 max-w-3xl text-xl text-indigo-100">Can't wait to speak with you soon! Your Property Cash Offer Team 🏡</p>


      <div class="flex flex-col mt-4 text-left">
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
  <a href="https://api.whatsapp.com/send?phone=447787269365" target="_blank">
    <i class="fab fa-whatsapp mr-2"></i> WhatsApp
  </a>
</div>
<div class="text-xl text-white mb-6">
  Click to message us on WhatsApp
</div>


        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-phone mr-2"></i> Phone
        </div>
        <div class="text-xl text-white mb-6">
          0800 077 6534
        </div>
  
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-envelope mr-2"></i> Email
        </div>
        <div class="text-xl text-white mb-6">
          hello@asaphousebuyers.co.uk
        </div>
      </div>
    </div>
  </div>
  </template>

<style>
.opacity-50 {
    opacity: 0.4;
}
</style>
    
    <script>
    export default {
      name: 'ThankYou',
      metaInfo() {
        return {
          title: 'Thank You - ASAP House Buyers',
          meta: [
            {
              name: 'description',
              content: 'Thank you for submitting your property details for a free cash offer! Our friendly and professional property consultant will be in touch with you shortly.',
            },
            {
              name: 'keywords',
              content: 'property cash offer, we buy any home, fast house sale, cash house buyers, thank you',
            },
          ],
          link: [
        { rel: 'canonical', href: 'https://asaphousebuyers.co.uk/thank-you' }
        ]
        };
      },
    };
    </script>
  
    