<template>

    <div class="relative bg-indigo-800">
      <div class="absolute inset-0">
        <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
      </div>
      <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Terms of Use</h1>
        <p class="mt-6 max-w-3xl text-xl text-indigo-100">Welcome to our website. By browsing and using this website, you agree to adhere to the following terms and conditions, which, in conjunction with our privacy policy, define ASAP House Buyer's relationship with you concerning this website. If you do not agree with any portion of these terms and conditions, please refrain from using our website.</p>
        
  
  <p class="mt-6 max-w-3xl text-xl text-indigo-100">The terms 'ASAP House Buyer,' 'us,' and 'we' refer to the website's owner, while 'you' refers to the user or viewer of our website. Your use of this website is governed by the following terms:</p>

  
  <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
            <li class="max-w-3xl text-xm text-indigo-100">
              The content on this website's pages is intended for general information and use only and is subject to change without prior notice.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
              We, along with any third parties, do not guarantee or warrant the accuracy, timeliness, performance, completeness, or appropriateness of the information and materials found or offered on this website for any specific purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
              Your use of any information or materials on this website is solely at your own risk, and we shall not be held liable for any consequences. It is your responsibility to ensure that any products, services, or information available through this website meet your specific needs.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
              This website features material owned by or licensed to us, including but not limited to design, layout, appearance, and graphics. Reproduction is prohibited except in accordance with the copyright notice, which is part of these terms and conditions.            </li>
            <li class="tmax-w-3xl text-xm text-indigo-100">
              All trademarks displayed on this website that are not owned by or licensed to the operator are acknowledged.</li>
              <li class="tmax-w-3xl text-xm text-indigo-100">
                Unauthorized use of this website may result in a claim for damages and/or constitute a criminal offense.</li>        
              
              <li class="tmax-w-3xl text-xm text-indigo-100">
                Occasionally, this website may contain links to other websites for your convenience and to provide further information. These links do not imply our endorsement of the website(s), and we assume no responsibility for the content of the linked website(s).</li>
                <li class="tmax-w-3xl text-xm text-indigo-100">
                  Your use of this website and any disputes arising from such use are subject to the laws of England, Northern Ireland, Scotland, Wales, and Jersey.</li>    
        
        
              </ol>



      
      </div>
    </div>
    </template>
  
  <style>
  .opacity-50 {
      opacity: 0.4;
  }
  </style>
      
      <script>
      export default {
        name: 'TermsOfUse',
        metaInfo() {
          return {
            title: 'Terms of Use - ASAP House Buyers',
            meta: [
              {
                name: 'description',
                content: 'Terms of Use',
              },
              {
                name: 'keywords',
                content: 'property cash offer, we buy any home, fast house sale, cash house buyers, thank you',
              },
            ],
            link: [
            { rel: 'canonical', href: 'https://asaphousebuyers.co.uk/terms-of-use' }
          ]
          };
        },
      };
      </script>
    
      