<template>

<div class="header-container flex flex-col items-center bg-cover bg-center relative" 
    :style="`background-image: url(${headerImageUrl})`"
    >
    <div class="overlay absolute inset-0 bg-blue-900 opacity-90"></div>      
      <div class="flex flex-col items-center z-10" :class="{ 'hidden': showQuiz || showContact }">
        <div class="key-points-container text-left sm:text-center pt-0 sm:pt-20 md:pt-10 px-5 sm:px-0">
          <h1 class="text-xl md:text-3xl font-extrabold text-white text-center pt-7 sm:text-center px-2">{{ lander.Title }}</h1>
          <ul class="mt-4 flex space-x-0 sm:space-x-4 md:space-x-6 justify-start sm:justify-center flex-wrap text-left text-white">
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Quick sale process
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> No need for viewings
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Completed in 7-21 days
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Flexible moving dates
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> No last-minute price drops
            </li>
          </ul>
          
        </div>
        <AddressForm @addressSelected="onAddressSelected" class="w-full px-4 sm:w-1/2 sm:px-0 mt-4" />


<div class="mt-4 small-device-text">
  <div class="w-full sm:max-w-sm mx-auto text-sm text-gray-300 pb-10">
    <p class="text-xs font-bold mb-2">
  <span class="text-red-500 mr-1">&#9888;</span>
  There is no obligation to accept our offer. We understand that each property has unique circumstances. Our team thoroughly evaluates every property based on its individual merit.
</p>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center pt-10 mx-4 justify-start z-10" :class="{ 'hidden': !showQuiz }">
        <div class="text-3xl font-bold text-white mb-8">
          Quick Property Evaluation
        </div>

        <QuizForm @completed="onQuizComplete" />
        <div class="sm:max-w-sm mx-auto text-sm text-white pt-5 pb-10" style="font-size: 12px;">
          Please note, this is a preliminary evaluation to determine your property's eligibility for a cash offer from us, not a comprehensive online valuation. We appreciate your understanding.
        </div>
      </div>

      <div class="flex flex-col items-center pt-10 justify-start z-10 pb-10" :class="{ 'hidden': !showContact }">
        <ContactForm :address-data="addressData" 
                      :quiz-data="quizData" 
                      :utmCampaign="utmCampaign" 
                      :utmSource="utmSource" 
                      :utmMedium="utmMedium"
                      :utmKeyword="utmKeyword" 
                      :utmDevice="utmDevice" />
      </div>
    </div>

    <div class="countdown-container py-4 bg-gray-100">
        <countdown-timer />
    </div>

    <div class="guaranteed-offer">
  <h2 class="font-bold mb-8 text-center" style="font-size: 1.5rem;">Want a Guaranteed Offer?</h2>
      <p class="px-4 mb-4">
        With no fees, complete flexibility on your moving date, 100% confidentiality, no stress and no estate agents! We provide speed, certainty, simplicity.
      </p>
      <div class="offer-steps">
        <div class="step">
          <i class="fas fa-comments chat-bubble-icon"></i>
          <p>Tell us about your property</p>
        </div>
        <div class="step">
          <i class="fas fa-handshake cash-offer-icon"></i>
          <p>Accept our cash offer</p>
        </div>
        <div class="step">
          <i class="fas fa-university bank-icon"></i>
          <p>Cash in the bank in 7 days</p>
        </div>
      </div>
      <p class="px-4 mb-4">
        Our service is simple, stress-free, giving your peace of mind.
      </p>
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="goToTop">
        Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
    </div>


<div class="bg-blue-800 full-width flex justify-center items-center text-white p-4">
  <div class="max-w-6xl p-3">
    <h2 class="text-2xl font-bold mb-8 text-center">{{ lander.TitleSub }}</h2>
  
    <div class="text-lg" v-html="lander.Content" v-if="!error"></div>
    <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="goToTop">
        Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>

  </div>
</div>


</template>




<script>
import axios from 'axios';
import CountdownTimer from "@/components/CountdownTimer.vue";
import AddressForm from "@/components/AddressForm.vue";
import QuizForm from "@/components/QuizForm.vue";
import ContactForm from "@/components/ContactForm.vue";
import KeyImage from '@/assets/key-image.png'; // This should be the path to your image


export default {
  components: {
    CountdownTimer,
    AddressForm,
    QuizForm,
    ContactForm,
  },
  data() {
    return {
      lander: {},
      error: false,
      keyImage: KeyImage,
      addressData: {},
      quizData: {},
      showQuiz: false,
      showAddressForm: true,
      showContact: false,
      utmCampaign: '',
      utmSource: '',
      utmMedium: '',
      utmKeyword: '',
      utmDevice: '',
      headerImageUrl: require("@/assets/houses-bg.jpg"),
    };
  },
  async mounted() {
    try {
      await this.fetchLanderData();
      this.updateMetaInfo();
    } catch (error) {
      console.error(error);
      this.error = true;
      this.redirectTo404();
    }
  },
  methods: {
    goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async fetchLanderData() {
      const response = await axios.get(`https://blog.asaphousebuyers.co.uk/api/landers?filters[Domain][$eq]=asaphousebuyers.co.uk&filters[slug][$eq]=${this.$route.params.slug}`);
      const landerData = response.data.data.find(item => item.attributes.slug === this.$route.params.slug);
      if (landerData) {
        this.lander = landerData.attributes; // Using the attributes from the matched item
      } else {
        throw new Error('No matching lander found');
      }
    },
    
    updateMetaInfo() {
      if (this.error) {
        // Handle error case
        document.title = 'Error | ASAP House Buyers';
        const errorDescription = 'An error occurred while fetching data.';
        const metaTags = [
          { name: 'description', content: errorDescription },
        ];
        this.setMetaTags(metaTags);
        return;
      }

      const title = this.lander.seoTitle || 'Get Cash for Your House in Just 7 Days';
      const description = this.lander.seoDescription || 'Are you looking to sell your house fast? We buy houses for cash, no matter the condition. We offer a quick and easy process, with no fees or commissions. Get cash for your house in just 7 days!';
      const keywords = this.lander.Keywords || 'Sell my house fast, We buy houses for cash, Get cash for your house, Sell your house fast and for top dollar, We buy houses in any condition';
      const image = "https://blog.asaphousebuyers.co.uk/uploads/house_family_65dd601c43.jpg" || '/unsure';

      const metaTags = [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      // Open Graph tags
      { property: 'og:title', content: title },
      { property: 'og:type', content: 'website' },
      { property: 'og:image', content: image }, // Replace URL_to_your_image with the actual URL to your image
      { property: 'og:url', content: window.location.href }, // Dynamically set the og:url to the current page URL
      { property: 'og:description', content: description },
    ];


      document.title = title;
      this.setMetaTags(metaTags);
    },
    onAddressSelected(address) {
      this.addressData = address;
      this.showQuiz = true;
      this.showAddressForm = false;
    },
    onQuizComplete(quizResults) {
      this.quizData = quizResults;
      this.showQuiz = false;
      this.showContact = true;
    },
    setMetaTags(tags) {
  tags.forEach(tag => {
    const existingTag = document.head.querySelector(`meta[name="${tag.name}"]`) ||
                       document.head.querySelector(`meta[property="${tag.property}"]`);
    if (existingTag) {
      existingTag.setAttribute('content', tag.content);
    } else {
      const newTag = document.createElement('meta');
      if (tag.name) {
        newTag.setAttribute('name', tag.name);
      } else if (tag.property) {
        newTag.setAttribute('property', tag.property);
      }
      newTag.setAttribute('content', tag.content);
      document.head.appendChild(newTag);
    }
  });
},

    redirectTo404() {
      this.$router.replace('/404'); // Replace with your actual 404 page route
    },
  },
};
</script>

