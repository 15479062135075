<template>

<div class="relative bg-indigo-900">
  <div class="absolute inset-0">
    <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-sign.jpg" alt="">
    <div class="absolute inset-0 bg-indigo-900 mix-blend-multiply" aria-hidden="true"></div>
  </div>
  <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
    <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">About Us</h1>
    <p class="mt-6 max-w-3xl text-xl text-indigo-100">Welcome to ASAP House Buyers, the one-stop solution for a quick and hassle-free property sale. We specialise in buying properties for cash, in any condition, and with a 100% no-fees guarantee. Our mission is to provide you with a simple, reliable, and efficient way to sell your property without the complexities and uncertainties of the traditional real estate market.</p>
    <p class="mt-6 max-w-3xl text-xl text-indigo-100">At ASAP House Buyers, we understand that every property is unique, and our team is committed to evaluating each property based on its individual merit. Our goal is to provide you with a fair cash offer, giving you a smooth, stress-free transaction with no hidden surprises.</p>
        <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/')">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>


  </div>
</div>
</template>

<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>
export default {
  name: 'AboutUs',
  metaInfo() {
    return {
      title: 'About Us | ASAP House Buyers',
      meta: [
        {
          name: 'description',
          content: 'All about ASAP House Buyers - Get a Free Cash Offer Today - A Team with 50 years experience.',
        },
        {
          name: 'keywords',
          content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
        },
         // Add Open Graph tags
        {
          property: 'og:title',
          content: 'About Us | ASAP House Buyers',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: 'https://asaphousebuyers.co.uk/img/sell-house-fast-im1.2fbc61ae.jpg', // Replace URL_to_your_image with the actual URL to your image
        },
        {
          property: 'og:url',
          content: 'https://asaphousebuyers.co.uk/about-us',
        },
        {
          property: 'og:description',
          content: 'ASAP House Buyers - A little about us and our sell house fast for cash service.',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://asaphousebuyers.co.uk/about-us' }
      ]
    };
  },
};
</script>
  

  