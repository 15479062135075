<template>
    <div class="bg-gradient-to-r from-indigo-900 via-indigo-800 to-blue-900 py-6 px-4 sm:px-6 lg:px-8 flex flex-col items-center text-center relative">
      <div class="max-w-3xl mx-auto space-y-10">
        <div class="text-white">
          <h2 class="mt-6 text-3xl sm:text-5xl font-extrabold">
            {{ lander.Title }}
          </h2>
          <div class="flex justify-between">
            <ul class="mt-6 space-y-4 text-lg">
              <li class="flex items-center text-sm lg:text-lg">
                <i class="fas fa-check text-green-500 mr-2"></i>
                Quick sale process
              </li>
              <li class="flex items-center text-sm lg:text-lg">
                <i class="fas fa-check text-green-500 mr-2"></i>
                No need for viewings
              </li>
              <li class="flex items-center text-sm lg:text-lg">
                <i class="fas fa-check text-green-500 mr-2"></i>
                Completed in 7 - 21 days
              </li>
              <li class="flex items-center text-sm lg:text-lg">
                <i class="fas fa-check text-green-500 mr-2"></i>
                Flexible moving dates
              </li>
              <li class="flex items-center text-sm lg:text-lg">
                <i class="fas fa-check text-green-500 mr-2"></i>
                No last minute price drops
              </li>
            </ul>
            <img :src="keyImage" class="h-32 w-32 z-50 pt-4" alt="Set of Sold Keys" />
          </div>
          <button class="mt-8 group relative w-full flex justify-center py-3 px-4 border border-transparent text-xl lg:text-2xl font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-200" @click="$router.push('/')">
            Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
          </button>
        </div>
        <div class="bg-blue-800 text-white p-6 rounded-lg shadow-lg space-y-6 relative">
          <h2 class="text-2xl sm:text-3xl font-extrabold">
            {{ lander.TitleSub }}
          </h2>
          <div class="text-lg" v-html="lander.Content" v-if="!error"></div>
          <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/')">
            Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
          </button>
        </div>
      </div>
    </div>

  

    <div class="guaranteed-offer">
  <h2 class="font-bold mb-8 text-center" style="font-size: 1.5rem;">Want a Guaranteed Offer?</h2>
      <p class="px-4 mb-4">
        With no fees, complete flexibility on your moving date, 100% confidentiality, no stress and no estate agents! We provide speed, certainty, simplicity.
      </p>
      <div class="offer-steps">
        <div class="step">
          <i class="fas fa-comments chat-bubble-icon"></i>
          <p>Tell us about your property</p>
        </div>
        <div class="step">
          <i class="fas fa-handshake cash-offer-icon"></i>
          <p>Accept our cash offer</p>
        </div>
        <div class="step">
          <i class="fas fa-university bank-icon"></i>
          <p>Cash in the bank in 7 days</p>
        </div>
      </div>
      <p class="px-4 mb-4">
        Our service is simple, stress-free, giving your peace of mind.
      </p>
    </div>


    <div class="bg-gray-100 flex items-start justify-center p-8">
  <div class="bg-white p-6 rounded-lg shadow-lg lg:flex lg:items-center w-full max-w-3xl">
    <div class="mt-2 lg:mt-0 lg:w-full">
      <div class="w-full max-w-3xl">
        <h2 class="text-2xl font-bold mb-4 text-center">ASAP House Buyers vs. Estate Agents</h2>
        <p class="text-center mb-4">Opt for ASAP House Buyers and enjoy a seamless, stress-free home sale. We manage all aspects of the transaction, including legalities, without third-party involvement. Experience the advantages of working with us over traditional estate agents.</p>
      </div>
      <div class="overflow-x-auto">
        <table class="w-full border-collapse small-device-table">
        <thead>
          <tr>
            <th class="py-2 font-bold text-left border-b-2 border-gray-200">Sales Process</th>
            <th class="py-2 font-bold text-right border-b-2 border-gray-200">ASAP house Buyers</th>
            <th class="py-2 font-bold text-right border-b-2 border-gray-200">Estate Agents</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">Duration</td>
            <td class="px-2 py-2 text-right bg-green-200 border-b border-gray-200">7 Days</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">5-7 Months</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">Market Values</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£100,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£100,000</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">Agreed Sales Price</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£90,000</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">After Agent Fees</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£88,000</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">After Conveyancing</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£86,000</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">After Housing Costs</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£82,000</td>
            </tr>
            <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">After Monthly Bills</td>
            <td class="px-2 py-2 text-right border-b border-gray-200 font-bold">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200 font-bold">£80,000</td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                  <td colspan="3" class="px-2 py-2 text-center text-sm italic small-device-table">
                    *This is a representative example; we typically offer up to 80% of the property market value for a cash purchase completed in 7 days. Additionally, we provide up to 3 months of rent-free stay, potentially saving an average of £750/month based on the average UK mortgage payment.
                  </td>
                </tr>
              </tfoot>
          </table>
        </div>
     </div>
  </div>
</div>

  </template>
  
  
  
  
  <script>
  import axios from 'axios';
  import KeyImage from '@/assets/key-image.png'; // This should be the path to your image
  
  export default {
    data() {
      return {
        lander: {},
        error: false,
        keyImage: KeyImage,
      };
    },
    async mounted() {
      try {
        await this.fetchLanderData();
        this.updateMetaInfo();
      } catch (error) {
        console.error(error);
        this.error = true;
        this.redirectTo404();
      }
    },
    methods: {
      async fetchLanderData() {
        const response = await axios.get(`https://blog.asaphousebuyers.co.uk/api/landers?filters[Domain][$eq]=asaphousebuyers.co.uk&filters[slug][$eq]=${this.$route.params.slug}`);
        const landerData = response.data.data.find(item => item.attributes.slug === this.$route.params.slug);
        if (landerData) {
          this.lander = landerData.attributes; // Using the attributes from the matched item
        } else {
          throw new Error('No matching lander found');
        }
      },
      updateMetaInfo() {
        if (this.error) {
          // Handle error case
          document.title = 'Error | ASAP House Buyers';
          const errorDescription = 'An error occurred while fetching data.';
          const metaTags = [
            { name: 'description', content: errorDescription },
          ];
          this.setMetaTags(metaTags);
          return;
        }
  
        const title = this.lander.seoTitle || 'Get Cash for Your House in Just 7 Days';
        const description = this.lander.seoDescription || 'Are you looking to sell your house fast? We buy houses for cash, no matter the condition. We offer a quick and easy process, with no fees or commissions. Get cash for your house in just 7 days!';
        const keywords = this.lander.Keywords || 'Sell my house fast, We buy houses for cash, Get cash for your house, Sell your house fast and for top dollar, We buy houses in any condition';
  
        const metaTags = [
          { name: 'description', content: description },
          { name: 'keywords', content: keywords },
        ];
  
        document.title = title;
        this.setMetaTags(metaTags);
      },
      setMetaTags(tags) {
        tags.forEach(tag => {
          const existingTag = document.head.querySelector(`meta[name="${tag.name}"]`);
          if (existingTag) {
            existingTag.setAttribute('content', tag.content);
          } else {
            const newTag = document.createElement('meta');
            newTag.setAttribute('name', tag.name);
            newTag.setAttribute('content', tag.content);
            document.head.appendChild(newTag);
          }
        });
      },
      redirectTo404() {
        this.$router.replace('/404'); // Replace with your actual 404 page route
      },
    },
  };
  </script>
  
  