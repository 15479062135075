<template>

    <div class="relative bg-indigo-800">
      <div class="absolute inset-0">
        <img class="h-full w-full object-cover opacity-50" src="@/assets/how-we-work.jpg" alt="How ASAP House Buyers Works">
        <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
      </div>
      <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">How We Work</h1>
        
        <p class="mt-6 max-w-3xl text-xl text-indigo-100"> Experience Trustworthy Cash Property Purchases with Us 🏠💰</p>

            <p class="mt-6 max-w-3xl text-xl text-indigo-100">Our expertise lies in purchasing properties for cash, a field we've been thriving in for years. We've successfully acquired, renovated, and either rented or sold a multitude of properties over time, solidifying our reputation as reliable and experienced buyers.</p>

                <p class="mt-6 max-w-3xl text-xl text-indigo-100">When you choose to work with us, we guarantee a seamless process. After evaluating your property, we'll present you with a guaranteed cash offer. If you accept, we'll formalize the agreement in writing through our solicitors, ensuring a transparent and trustworthy transaction.</p>

                    <p class="mt-6 max-w-3xl text-xl text-indigo-100">As part of our commitment to your satisfaction, we'll cover your conveyancing costs and grant you full control over the entry date. If you need funds quickly, we can settle in as little as 7 days. If you require more time, we're happy to accommodate your needs.</p>

                        <p class="mt-6 max-w-3xl text-xl text-indigo-100">Choose us for a trustworthy and hassle-free cash property purchase experience.</p>
        
        
        
        
        <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/')">
          Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
        </button>
    
    
      </div>
    </div>
    
    </template>
    <style>
    .opacity-50 {
        opacity: 0.3;
    }
    </style>

      
      <script>
      export default {
          name: 'HowWeWork',
          metaInfo() {
            return {
              title: 'How We Work | ASAP House Buyers',
              meta: [
                {
                  name: 'description',
                  content: 'How ASAP House Buyers Works - Get a Free Cash Offer Today - A Team with 50 years experience.',
                },
                {
                  name: 'keywords',
                  content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
                },
                // Add Open Graph tags
                {
                  property: 'og:title',
                  content: 'How We Work | ASAP House Buyers',
                },
                {
                  property: 'og:type',
                  content: 'website',
                },
                {
                  property: 'og:image',
                  content: 'https://asaphousebuyers.co.uk/img/sell-house-fast-im1.2fbc61ae.jpg', // Replace URL_to_your_image with the actual URL to your image
                },
                {
                  property: 'og:url',
                  content: 'https://asaphousebuyers.co.uk/how-we-work',
                },
                {
                  property: 'og:description',
                  content: 'ASAP House Buyers - How we work.',
                },
              ],
              link: [
                { rel: 'canonical', href: 'https://asaphousebuyers.co.uk/how-we-work' }
               ]
            };
          },
        };
      </script>
      
    
      