<template>
    <div id="quiz-form" class='bg-white shadow-md rounded p-8' ref='quizForm'>
      <div v-if='currentQuestionIndex < questions.length'>
        <div class='flex items-center justify-between mb-1'>
          <div class='text-sm font-bold'>
            Step {{ currentQuestionIndex + 1 }} of {{ questions.length }}
          </div>
        </div>
        <div class='border-b-2 border-gray-200 mb-2'></div>
        <div class='text-xl mb-4 text-blue-500 font-semibold text-center px-2'>
          {{ questions[currentQuestionIndex].question }}
        </div>
        <select
          v-model='selectedAnswer'
          class='custom-dropdown group hover:bg-blue-200 cursor-pointer bg-gray-200 w-full rounded px-2 py-3 flex items-center mb-2'
        >
          <option value='' disabled>Select an option...</option>
          <option
            v-for='(answer, answerIndex) in questions[currentQuestionIndex].answers'
            :key='answerIndex'
            :value='answer'
          >{{ answer.text }}</option>
        </select>
        <p class='text-gray-500 flex items-center p-0 text-right pt-2'>
          <i class='text-green-600 fas fa-shield-alt mr-1'></i>
          Safe, secure &amp; confidential
        </p>
      </div>
      <div v-else>
        <div v-if='loading' class='flex items-center'>
          <div class='spinner w-8 h-8'></div>
          <span class='ml-2'>Evaluating your property...</span>
        </div>
        <div v-else>
          <div class='text-md mb-4'>
            <div class='container mx-auto max-w-md'>
              <p class="pb-2">
                Great!, Based on our quick evaluation, we would like to make a cash offer on your property. We just need a few contact details, request your no-obligation cash offer below.
              </p>
            </div>
                <button id="free-cash-offer-button" class='bg-green-600 text-white font-bold py-3 px-4 rounded w-full shadow-md transform hover:shadow-lg transition duration-200'
                @click='submitQuiz()'>
                👉 Request My Free Cash Offer
              </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <style>
  .custom-dropdown {
    border: 1px solid black;
    appearance: none;
  }
  
  .spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  
  <script>
  export default {
    name: "QuizForm",
    data() {
      return {
        questions: [
          {
            question: 'Are you currently already on the market?',
            answers: [
              { text: 'Yes' },
              { text: 'No' },
            ],
          },
          {
            question: 'What type of property are you selling?',
            answers: [
              { text: 'Detached' },
              { text: 'Flat'},
              { text: 'Semi Detached' },
              { text: 'Terraced' },
              { text: 'Bungalow' },
              { text: 'Land' },
              { text: 'Other' },
            ],
          },
          {
            question: 'How many bedrooms?',
            answers: [
              { text: '1' },
              { text: '2' },
              { text: '3' },
              { text: '4' },
              { text: '5' },
              { text: '6' },
              { text: '7' },
            ],
          },
          
          {
            question: 'Reason for sale?',
            answers: [

              { text: 'Financial' },
              { text: 'Stop Repossession'},
              { text: 'Stop Eviction' },
              { text: 'Mortgage Arrears' },
              { text: 'Other' },
            ],
          },
          {
            question: 'How fast do you need to sell?',
            answers: [
              { text: 'ASAP' },
              { text: 'Within 7 Days' },
              { text: '1 - 4 Weeks' },
              { text: '5 Weeks Plus' },
            ],
          },
          {
            question: 'What is the property condition?',
            answers: [
              { text: 'Good' },
              { text: 'Average' },
              { text: 'Needs TLC' },
            ],
          },
        ],
        currentQuestionIndex: 0,
        answers: [],
        selectedAnswer: '',
        loading: false,
      };
    },
    watch: {
    selectedAnswer(answer) {
      if (answer) {
        this.answers.push(answer);
        
        // Proceed to the next question after ensuring the DOM has been updated
        this.$nextTick(() => {
          this.selectedAnswer = ''; // Reset selectedAnswer in the next DOM update cycle
          this.currentQuestionIndex++;
  
          // Check if all questions have been answered
          if (this.currentQuestionIndex >= this.questions.length) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.submitQuiz();
            }, 3000);
          }
        });
      }
    },
  },
  
  
    methods: {
      scrollToTop() {
        this.$refs.quizForm.scrollIntoView({ behavior: "smooth", block: "start", inline: "start", scrollBehavior: "auto" });
      },
  
      constructQuizData() {
        return {
          apiParam1: this.answers[0].text,
          apiParam2: this.answers[1].text,
          apiParam3: this.answers[2].text,
          apiParam4: this.answers[3].text,
          apiParam5: this.answers[4].text,
          apiParam6: this.answers[5].text,
        };
      },
      submitQuiz() {
        const quizData = this.constructQuizData();
        this.$emit('completed', quizData);
      },
    },
  
    mounted() {
      this.scrollToTop();
      this.selectedAnswer = ''; // Reset selectedAnswer when component is mounted
    },
  };
  </script>
  
  