
<template>
  <div class="relative bg-indigo-900">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/mapuk.jpg" alt="UK Map with Sold for cash pins">
      <div class="absolute inset-0 bg-indigo-900 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-7xl px-4 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl shadow-md">Where We Buy</h1>
      <p class="mt-6 text-xl text-indigo-100 max-w-3xl">
        Offering comprehensive property buying solutions across the entire UK. No matter where you are, we're here for you.
      </p>
      <ul class="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-white">
        <li><a href="https://asaphousebuyers.co.uk/location/English%20Cities">English Cities</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Bath">Bath</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Birmingham">Birmingham</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Bradford">Bradford</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Brighton%20%26%20Hove">Brighton &amp; Hove</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Bristol">Bristol</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Cambridge">Cambridge</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Canterbury">Canterbury</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Carlisle">Carlisle</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Chelmsford">Chelmsford</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Chester">Chester</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Chichester">Chichester</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Coventry">Coventry</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Derby">Derby</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Durham">Durham</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Ely">Ely</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Exeter">Exeter</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Gloucester">Gloucester</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Hereford">Hereford</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Kingston%20upon%20Hull">Kingston upon Hull</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Lancaster">Lancaster</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Leeds">Leeds</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Leicester">Leicester</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Lichfield">Lichfield</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Lincoln">Lincoln</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Liverpool">Liverpool</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/City%20of%20London">City of London</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Manchester">Manchester</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Newcastle%20upon%20Tyne">Newcastle upon Tyne</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Norwich">Norwich</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Nottingham">Nottingham</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Oxford">Oxford</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Peterborough">Peterborough</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Plymouth">Plymouth</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Portsmouth">Portsmouth</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Preston">Preston</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Ripon">Ripon</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Salford">Salford</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Salisbury">Salisbury</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Sheffield">Sheffield</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Southampton">Southampton</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/St%20Albans">St Albans</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Stoke-on-Trent">Stoke-on-Trent</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Sunderland">Sunderland</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Truro">Truro</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Wakefield">Wakefield</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Wells">Wells</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Westminster">Westminster</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Winchester">Winchester</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Wolverhampton">Wolverhampton</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Worcester">Worcester</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/York">York</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Scottish%20Cities">Scottish Cities</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Aberdeen">Aberdeen</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Dundee">Dundee</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Edinburgh">Edinburgh</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Glasgow">Glasgow</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Inverness">Inverness</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Perth">Perth</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Stirling">Stirling</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Welsh%20Cities">Welsh Cities</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Bangor">Bangor</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Cardiff">Cardiff</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Newport">Newport</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/St%20Asaph">St Asaph</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/St%20David's">St David's</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Swansea">Swansea</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Northern%20Irish%20Cities">Northern Irish Cities</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Armagh">Armagh</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Belfast">Belfast</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Londonderry">Londonderry</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Lisburn">Lisburn</a></li>
        <li><a href="https://asaphousebuyers.co.uk/location/Newry">Newry</a></li>
    </ul>
    </div>
  </div>
</template>




<style scoped>
.opacity-50 {
    opacity: 0.3;
}
.hover\:text-indigo-300:hover {
  color: #cbd5e1; /* Adjust the color to fit your design */
}
</style>
  
<script>
export default {
  name: 'WhereWeBuy',
  metaInfo() {
    return {
      title: 'Where We Buy | ASAP House Buyers',
      meta: [
        {
          name: 'description',
          content: 'ASAP House Buyers - We buy locally through the UK',
        },
        {
          name: 'keywords',
          content: 'local cash buyers, property cash offer, we buy any home, fast house sale, cash house buyers',
        },
        // Add Open Graph tags
        {
          property: 'og:title',
          content: 'Where We Buy | ASAP House Buyers',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: 'https://asaphousebuyers.co.uk/img/sell-house-fast-im1.2fbc61ae.jpg', // Replace URL_to_your_image with the actual URL to your image
        },
        {
          property: 'og:url',
          content: 'https://asaphousebuyers.co.uk/where-we-buy',
        },
        {
          property: 'og:description',
          content: 'ASAP House Buyers - We buy locally through the UK',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://asaphousebuyers.co.uk/where-we-buy' }
      ]
    };
  },
};
</script>
  

  