<template>
  <div></div>
</template>

<script>
export default {
  name: 'EmptyContent',
  metaInfo() {
    return {
      title: 'Sell Your House Fast in 7-21 Days - ASAP House Buyers',
      meta: [
        {
          name: 'description',
          content: 'ASAP House Buyers specialise in purchasing property for cash, fast with 100% No Fees. Get a Free Cash Offer Today.',
        },
        {
          name: 'keywords',
          content: 'property cash offer, we buy any home, fast house sale, cash house buyers, sell house fast',
        },
           // Add Open Graph tags
         {
          property: 'og:title',
          content: 'Sell Your House Fast in 7 - 21 Days | ASAP House Buyers',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: 'https://asaphousebuyers.co.uk/img/sell-house-fast-im1.2fbc61ae.jpg', // Replace URL_to_your_image with the actual URL to your image
        },
        {
          property: 'og:url',
          content: 'https://asaphousebuyers.co.uk/about-us',
        },
        {
          property: 'og:description',
          content: 'ASAP House Buyers (UK) - Get a fair price for your property in 7 - 21 days. Just Enter Postcode and request an offer. 100% No Fees.',
        },
      ],
    };
  },
};
</script>
