<template>

    <div class="relative bg-indigo-800">
      <div class="absolute inset-0">
        <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
      </div>
      <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Privacy Policy</h1>
        
        <p class="mt-6 max-w-3xl text-xm text-indigo-100"> At ASAP House Buyers (UK), we are committed to protecting your privacy and ensuring the consistent treatment of your personal data. 
        This privacy policy outlines how we collect, process, and safeguard your personal information when you use our website or engage with our services, including requesting a cash offer, completing a contact form, or participating in a competition.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100"> Please note that our website is not intended for children, and we do not knowingly collect data relating to children.</p>

            <p class="mt-6 max-w-3xl text-xm text-indigo-100"> Consent – The Information We Collect
              When you request a cash offer or information regarding our property services, we require your name, email address, telephone number, and property details. We collect this information to effectively handle your enquiry, process any subsequent offer and purchase, and communicate with you regarding our services and any other relevant matters. By submitting these details, you are consenting to be contacted by us via phone, SMS, email, and WhatsApp for the purposes of discussing your property offer.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100"> We collect this information to effectively handle your enquiry, process any subsequent offer and purchase, and communicate with you regarding our services and any other relevant matters.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100"> We may collect, use, store, and transfer different types of personal data about you, which we have grouped together as follows:</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Introduction of AI Chatbot Services<br />
    At ASAP House Buyers (UK), we use an AI-powered chatbot to handle initial SMS inquiries and interactions efficiently. This service helps us collect necessary information and schedule callbacks, improving our response time and customer service.</p>

    <p class="mt-6 max-w-3xl text-xm text-indigo-100">Data Collection by the AI Chatbot<br />
    The AI chatbot may collect additional types of personal data during interactions, such as responses to inquiries and scheduling preferences. However, we do not share any sensitive personal data with our AI chatbot. Sensitive data remains strictly confidential and is handled with the highest level of security.</p>

    <p class="mt-6 max-w-3xl text-xm text-indigo-100">Data Privacy and Security with AI Chatbot Interactions<br />
    We prioritise your privacy and security in every interaction with our AI chatbot. The chatbot is designed to collect only the necessary information required to assist you, excluding any sensitive personal data. Our technical and organizational measures ensure your data is protected according to our high standards.</p>
        
        
        <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
            <li class="max-w-3xl text-xm text-indigo-100">
                Identity Data: Includes first name, maiden name, last name or similar identifier, marital status, title, date of birth, and gender.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                Contact Data: Includes postal address, email address, and telephone numbers.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                Profile Data: Includes orders made by you, your interests, preferences, feedback, and survey responses.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                Technical Data: Includes GA Client ID, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website.
            </li>
            <li class="tmax-w-3xl text-xm text-indigo-100">
                Usage Data: Includes information about how you use our website.
            </li>
        </ol>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">We also collect and use Aggregated Data, such as statistical or demographic data, for various purposes. </p>
  

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Aggregated data may be derived from your personal data but is not considered personal data in law as it does not directly or indirectly reveal your identity.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">How We Use Your Personal Data</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">To provide our services, it may be necessary to share a limited amount of your personal contact information with third parties, such as solicitors, surveyors, and estate agents, solely to facilitate the service you have requested. We may also use your contact information to reach out to you via phone, SMS, email, and WhatsApp to discuss your property offer. From time to time, we may send you relevant marketing offers that we believe may be of interest to you. However, you will always have the opportunity to opt-out of receiving such communications.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">We will never pass your details onto an irrelevant third party. Your data will only be used to facilitate your cash offer and/or provide you with information and special offers related to our services.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">We will use your personal data only when the law permits us to do so. Commonly, we will use your personal data in the following circumstances:</p>



        <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
            <li class="max-w-3xl text-xm text-indigo-100">
                To perform the contract we have entered into, or are about to enter into, with you.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                To meet our legitimate interests, as long as your interests and fundamental rights do not override those interests.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                To comply with legal or regulatory obligations.
            </li>
        </ol>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Data Retention – How Long We Keep Your Data</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">ASAP House Buyers (UK) will retain your personal data for as long as you are a customer of our company, plus an additional 6 years, subject to any overriding legal or regulatory requirements.</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">As a customer of ASAP House Buyers (UK), you have the right to be forgotten. If you would like your personal data to be removed from our system, you can do so by making a written request to our data officer at:</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">ASAP House Buyers (UK) - 124 City Road, London, EC1V 2NX</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Email: data@asaphousebuyers.co.uk</p>


        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Data Subject Rights – Your Rights to Access Personal Information</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">In accordance with GDPR, you have the right to request a copy of the personal information held by ASAP House Buyers (UK) and to request that any inaccurate data be corrected or removed.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">As a customer of ASAP House Buyers (UK), your personal rights include:</p>

        <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
            <li class="max-w-3xl text-xm text-indigo-100">
                The right to be informed about what information we hold and the purpose for processing your personal data.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                The right to access and rectify any personal data held about you.
            </li>
            <li class="max-w-3xl text-xm text-indigo-100">
                The right to request that your personal data be erased from our system.
            </li>
        </ol>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Upon written request to our Data Protection Officer, we will provide a statement regarding the personal data held about you.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Changes to the Privacy Policy and Your Duty to Inform Us of Changes to Your Details
        If any of the information that you have provided to ASAP House Buyers (UK) changes, such as your email address, telephone number, or 
        name, please let us know by sending an email to data@asaphousebuyers.co.uk or by sending a letter to:</p>
        
                
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">ASAP House Buyers (UK) - 124 City Road, London, EC1V 2NX</p>


        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Cookies</p>
        
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
        We use a variety of cookies on our site to enhance user experience and analyze site usage. 
        If you are unfamiliar with cookies or how to control or delete them, we recommend reviewing detailed guidance here <a href="https://www.aboutcookies.org/">About Cookies</a>.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
        By using our site, you agree to our 'implied consent' cookie policy, which assumes that you are content with this usage. 
        If you are not happy with this policy, you can either discontinue using our site or delete our cookies after visiting the site. 
        You can also browse our site using your browser's anonymous usage setting – such as 'Incognito' in Chrome, 'InPrivate' for Internet Explorer, or 'Private Browsing' in Firefox and Safari.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
        For a description of the cookies we use on our site and their purposes, please review the section on cookies in our privacy policy.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Third-Party Services and Analytics Tools</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
            Our website uses third-party services to enhance user experience, analyze site usage, and assist in our marketing efforts. Specifically, we use Go HighLevel CRM for customer relationship management, Google Analytics for website traffic analysis, and Facebook pixels for advertising analytics. These tools help us understand how visitors interact with our website, manage our customer relationships effectively, and tailor our marketing strategies to better suit our audience's needs.
        </p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
            These tools collect information such as your IP address, your interaction with the website, and your user behavior, which allows us to improve our website and provide you with a more personalized experience. The data collected through Google Analytics and Facebook pixels may also be used to compile reports and to help us improve the site.
        </p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
            For more information on how these tools collect and use your information, please refer to their respective privacy policies. You have the option to opt out of tracking by these tools at any time by adjusting your browser settings or through the specific opt-out options provided by each tool.
        </p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
            We take your privacy seriously and ensure that all third-party services we use are compliant with relevant privacy laws and regulations to protect your data.
        </p>


        <p class="mt-6 max-w-3xl text-xm text-indigo-100">
        How We Protect Your Information</p>

       <p class="mt-6 max-w-3xl text-xm text-indigo-100"> The internet is not a completely secure medium. However, we have implemented various security measures, 
        including firewalls to prevent unauthorized external access to our systems and data. We also keep your information confidential, 
        and our internal procedures cover the storage, access, and disclosure of your information. Access to visitor information is limited, and all files are password protected.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Telephone Calls – Calls may be recorded for training and monitoring purposes. Any call recordings are held according to our data security procedures.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Personal Data Breaches and Complaints</p>
        <p class="mt-6 max-w-3xl text-xm text-indigo-100">If you believe your personal data has been misused or that we have not kept your personal details secure, you should contact our Data Protection Officer to log a complaint.</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">If you are dissatisfied with our response or require further advice, you may contact the Information Commissioner's Office (ICO).</p>

        <p class="mt-6 max-w-3xl text-xm text-indigo-100">Company Registration Details<br />
        ASAP House Buyers (UK) (UK)<br />
        Units 6 & 7<br />
        Eastway Business Village<br />
        Fulwood<br />
        Preston, PR2 9WT.<br />Company No: 15500808</p>


        
      </div>
    </div>
    
    </template>
      
      <script>
    
      
      export default {
          name: 'PrivacyPolicy',
          metaInfo() {
            return {
              title: 'Privacy Policy | ASAP House Buyers (UK)',
              meta: [
                {
                  name: 'description',
                  content: 'Privacy Policy ASAP House Buyers (UK) Works - Get a Free Cash Offer Today - A Team with 50 years experience.',
                },
                {
                  name: 'keywords',
                  content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
                },
              ],
              link: [
                { rel: 'canonical', href: 'https://asaphousebuyers.co.uk/privacy-policy' }
              ]
            };
          },
        };
      </script>
      
    
      